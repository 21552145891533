import React from "react";
import img01 from "../../assets/img/images/liquidity.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../lib/helpers";

const Liquidity = (props) => {
  return (
    <section id="liquidity" className="about-area pt-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                  <span>DEEP DEEP LIQUIDITY</span> on all chains!
                </h2>
              </div>
              <p>
                <b>EXTREMELY HIGH LAUNCH LIQUIDITY</b> with increased rewards for 
                community liquidity stakers starting immediately at launch. Starting 
                liquidity will be equal or greater than the aggregate marketcap of 
                the tokens across all chains. 
              </p>
              <p>
                Liquidity pools span multiple chains, giving freedom to move tokens 
                around through the built in <b>CROSS CHAIN</b> functionality of the token.
              </p>
              <button className="btn" onClick={scrollToTop}>
                Purchase Presale
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Liquidity;
