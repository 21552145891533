import { React, useState, useEffect } from "react";
import {
	useDisconnect,
	usePrepareContractWrite,
	useContractWrite,
	useNetwork,
	useSwitchNetwork,
	useWaitForTransaction,
	useContractRead,
	useAccount,
	erc20ABI,
} from "wagmi";

import { parseEther, formatEther, parseUnits, formatUnits } from "viem";

import { useDebounce } from "use-debounce";
import Countdown from "react-countdown";
import TokenBalance from "../TokenBalance/TokenBalance";
import BuyboxPurchaseLevel from "./BuyboxPurchaseLevel";
import BuyboxPurchaseBtn from "./BuyboxPurchaseBtn";
import TokensPulse from "./TokenLists/TokensPulse";
import TokensEthereum from "./TokenLists/TokensEthereum";
import TokensBSC from "./TokenLists/TokensBSC";
import TokensPolygon from "./TokenLists/TokensPolygon";
import Networks from "./Networks";
import Select from "react-select";
import Approve from "../Approve/Approve";
import { addressZero } from "../Constants";

const BuyboxPurchase = () => {
	const [amount, setAmount] = useState(0);

	const [selectedToken, setSelectedToken] = useState("");
	const [selectedTokenItem, setselectedTokenItem] = useState(null);
	const [tokens, setTokens] = useState([]);
	const [selectedNetwork, setSelectedNetwork] = useState(null);
	const [targetContract, setTargetContract] = useState("");

	const [debouncedAmount] = useDebounce(amount, 750);

	const { address: walletAddr, isConnecting, isDisconnected } = useAccount();

	console.log(`BuyboxPurchase fired`);

	const {
		data: allowanceAmount,
		isError,
		isLoading,
		isRefetching,
		refetch,
	} = useContractRead({
		address: selectedToken,
		abi: erc20ABI,
		chainId: selectedNetwork,
		functionName: "allowance",
		args: [walletAddr, targetContract],
		enabled: Boolean(selectedToken && (selectedToken != addressZero) & selectedNetwork && targetContract && debouncedAmount),
	});

	console.log("Allowance amount");
	console.log(allowanceAmount);

	const { chains, error, isLoadingChain, pendingChainId, switchNetwork } = useSwitchNetwork();
	const { chain } = useNetwork();

	const handleTokenSelection = (e, a) => {
		console.log("Slec");
		console.log(e.value);
		console.log(a);

		setselectedTokenItem(e);
		setSelectedToken(e.value);
	};

	const handleApproval = async (notification) => {
		console.log(notification);
		await refetch();
	};

	useEffect(() => {
		console.log("chain");
		console.log(chain);
		// console.log(chain.network)
		// console.log(chain.id)

		setselectedTokenItem(null);
		let tokenOptions = null;

		if (selectedNetwork === 56) {
			tokenOptions = TokensBSC;
			setTargetContract("0xE296e031FE03b9e8f29BD37AB6b3b62f224b1Ddf");
		} else if (selectedNetwork === 369) {
			tokenOptions = TokensPulse;
			setTargetContract("0xbe9f458df19238BfAB43f7773bC78B1332a0F9Be");
		} else if (selectedNetwork === 137) {
			tokenOptions = TokensPolygon;
			setTargetContract("0xBB168dE255b2D51b31Fe3CD89682b2080d2ccFF1");
		}

		if (tokenOptions) {
			setTokens(tokenOptions);
			setselectedTokenItem(tokenOptions[0]);
			setSelectedToken(tokenOptions[0].value);
		}
	}, [selectedNetwork]);

	return (
		<div className="container bg-dark rounded-3 p-3">
			<div className="row d-flex">
				<div className="col-lg-12">
					Presale is over! NFT Mints / Staking / Bridging launching shortly!<br />
					Liquidity has been added, trading is activated!<br />
					Our Ghosts and Goblins are hard at work bring our Soul minter online!
				</div>
			</div>
		</div>
	);
};

export default BuyboxPurchase;
