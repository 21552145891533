import React from "react";
import img01 from "../../assets/img/images/bridge.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../lib/helpers";

const Bridge = (props) => {
  return (
    <section id="bridge" className="about-area pt-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                  tSOUL and SoulNFTs are <span>CROSS CHAIN NATIVE</span>!
                </h2>
              </div>
              <p>
                Bridge your tSOUL tokens and SoulNFTs to your favorite chain. Near <b>INSTANT 
                TRANSACTIONS</b> between Binance, Ethereum, Polygon, and Pulse. 
              </p>
              <p>
                Bridge your SoulNFT to list on <b>DIFFERENT CHAIN MARKETPLACES</b>. No longer are 
                NFTs restricted to one ecosystem! 
              </p>
              <p>
                <b>ARBITRAGE</b> token price <b>OPPORTUNITIES</b> for profiting on mismatches! 
                Take advantage of the natural ebb and flow of the different blockchains to
                profit from volatility even in a bear market. Buy tSOUL cheaper on one chan, 
                and <b>SELL FOR A PROFIT</b> on another!
              </p>
              <button className="btn" onClick={scrollToTop}>
                Purchase Presale
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bridge;
