import React from "react";
import Countdown from "react-countdown";

const CountDown = () => {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count min">
            <span>{"00"}</span>days
          </div>
          <div className="time-count min">
            <span>{"00"}</span>hours
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minutes
          </div>
          <div className="time-count min">
            <span>{"00"}</span>seconds
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count min">
            <span>{days < 10 ? "0" + days : days}</span>{days == 1 ? "day":"days"}
          </div>
          <div className="time-count min">
            <span>{hours < 10 ? "0" + hours : hours}</span>{hours == 1 ? "hour":"hours"}
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>{minutes == 1 ? "minute":"minutes"}
          </div>
          <div className="time-count min">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>{seconds == 1 ? "second":"seconds"}
          </div>
        </>
      );
    }
  };

  return (
    <div className="coming-time">
      <Countdown date={1698537600000} renderer={renderer} />
    </div>
  );
};

export default CountDown;
