import React from "react";
import img01 from "../../assets/img/images/soul.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../lib/helpers";

const NFTs = (props) => {
  return (
    <section id="nft" className="about-area pt-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                  <span>SoulNFTs</span> Soul Collateralization
                </h2>
              </div>
              <p>
                Own the rights to your soul on the blockchain. Mint your Soul to an
                NFT to <b>TRADE, SWAP, COLLECT, or SELL</b>! Collateralize your Soul 
                (or buy someone else's) and let the contract recordkeeping up to us!
              </p>
              <p>
                Free to list on marketplaces, <b>NO TAXES, NO ROYALTIES</b>. You own your souls.
                Do with them what you wish.
              </p>
              <p> 
                Soul NFTs are cross chain native so you are free to <b>LET YOUR SOUL 
                TRAVEL</b> amongst the chains!
              </p>
              <button className="btn" onClick={scrollToTop}>
                Purchase Presale
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NFTs;
