import React from "react";
import img01 from "../../assets/img/team/team_img01.png";
import img02 from "../../assets/img/team/team_img02.png";
import img03 from "../../assets/img/team/team_img03.png";
import img04 from "../../assets/img/team/team_img04.png";
import img05 from "../../assets/img/team/team_img05.png";
import img06 from "../../assets/img/team/team_img06.png";
import img07 from "../../assets/img/team/team_img07.png";
import TeamItem from "./TeamItem";

const Team = () => {
  const team_members = [
    {
      src: img01,
      name: "Charon",
      designation: "Founder",
    },
    {
      src: img02,
      name: "Thanatos",
      designation: "Marketing",
    },
    {
      src: img03,
      name: "Nyx",
      designation: "Marketing",
    },
    {
      src: img04,
      name: "Erebus",
      designation: "Engineer",
    },
    {
      src: img05,
      name: "Nisaba",
      designation: "Engineer"
    },
    {
      src: img06,
      name: "Psychopomps",
      designation: "Tokenomics"
    },
    {
      src: img07,
      name: "Enki",
      designation: "Advisor"
    }
  ];
  return(<></>);
  return (
    <section className="team-area pt-130">
      <div className="row justify-content-center">
        {team_members.map((member, index) => (
          <div key={index} className="col-xl-3 col-md-4 col-sm-6">
            <TeamItem item={member} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
