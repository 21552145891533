import React from "react";
import Banner from "../../components/Banner/Banner";
import Team from "../../components/Team/Team";
import TopPartners from "../../components/TopPartners/TopPartners";
import Layout from "../../layouts/Layout";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import TLDR from "../../components/TLDR/TLDR";
import NFTs from "../../components/NFTs/NFTs";
import Bridge from "../../components/Bridge/Bridge";
import Staking from "../../components/Staking/Staking";
import Arbitrage from "../../components/Arbitrage/Arbitrage";
import Testimonials from "../../components/Testimonials/Testimonials";
import DEX from "../../components/DEX/DEX";
import Inflation from "../../components/Inflation/Inflation";
import Liquidity from "../../components/Liquidity/Liquidity";
import Revenue from "../../components/Revenue/Revenue";

const Home = () => {
	return (
		<Layout>
			<main className="fix">
				<Banner />

				<TopPartners />

				{/*<WhyChooseUs />*/}

				{/*<Testimonials />*/}

				<Tokenomics />

				<Staking />

				<Inflation />

				<Liquidity />

				<NFTs />

				<Revenue />

				<Arbitrage />

				<Bridge />

				<DEX />

				<TLDR />

				<Team />
			</main>
		</Layout>
	);
};

export default Home;
