import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import { useLocation } from "react-router-dom";
// import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

// import { WagmiConfig } from "wagmi";
import { mainnet, bsc, polygon, pulsechain } from "wagmi/chains";

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { walletConnectProvider, EIP6963Connector } from "@web3modal/wagmi";

import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";

ReactGA.initialize("G-7ZR8Y0MYMP");

// 1. Get projectId
const projectId = "8d1ab698ca7067f9e726b3cc0da7787e";

// 2. Create wagmiConfig
// const chains = [mainnet, bsc, polygon, pulsechain];
// const { chains, publicClient } = configureChains([bsc, polygon, pulsechain], [walletConnectProvider({ projectId }), publicProvider()]);
const { chains, publicClient } = configureChains(
	[bsc, polygon, pulsechain],
	// @note this is a fallback
	[
		// jsonRpcProvider({
		// 	rpc: (chain) => {
		// 		const options = { 56: "https://binance.llamarpc.com", 137: "https://polygon.llamarpc.com", 369: "https://pulsechain.publicnode.com" };

		// 		return {
		// 			http: options[chain.id],
		// 		};
		// 	},
		// }),
		// walletConnectProvider({
		// 	rpc: {
		// 		56: "https://binance.llamarpc.com",
		// 		137: "https://polygon.llamarpc.com",
		// 		369: "https://pulsechain.publicnode.com",
		// 	},
		// }),
		// walletConnectProvider({
		// 	projectId: projectId,
		// }),
		// infuraProvider({ apiKey: "71f819f767a040dcbd283cb3106499f0" }),
		publicProvider(),
	]
);

// 2. Create wagmiConfig
const metadata = {
	name: "SoulFi",
	description: "SoulFi",
	url: "https://soulfi.net",
	icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

// const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
const wagmiConfig = createConfig({
	autoConnect: true,
	connectors: [
		new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
		// new EIP6963Connector({ chains }),
		new InjectedConnector({ chains, options: { shimDisconnect: true } }),
		// new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } }),
	],
	publicClient,
});

createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
	useEffect(() => {
		const wow = new WOW({
			boxClass: "wow",
			animateClass: "animated",
			offset: 0,
			mobile: false,
			live: true,
		});
		wow.init();
	}, []);

	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<>
			<WagmiConfig config={wagmiConfig}>
				<Routes>
					<Route path="/" element={<Home />} />
				</Routes>
			</WagmiConfig>
		</>
	);
}

export default App;
