import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import PageLoader from "../components/PageLoader/PageLoader";
import cn from "classnames";

const Layout = (props) => {
  return (
    <div className={cn("")}>
      <PageLoader />
      <Header />
      {props.children}

      <Footer />
    </div>
  );
};

export default Layout;
