import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { scrollToTop } from "../../lib/helpers";

const Footer = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="The Soul Collection Logo" />
                  </Link>
                  <div className="footer-content">
                    <ul className="footer-social">
                    <li>
                      <a href="https://t.me/SoulFi_net" target="_blank">
                        <i className="fab fa-telegram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/TheSoulFi" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>                    
                    <li>
                      <a href="https://github.com/TheSoulCollection" target="_blank">
                        <i className="fab fa-github"></i>
                      </a>
                    </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-9 small">
                <small>
                  THIS SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. 
                  NO REPRESENTATIONS OR WARRANTIES OF ANY KIND ARE MADE, EXPRESS
                  OR IMPLIED, AS TO THE OPERATION OF THE SITE OR THE INFORMATION,
                  CONTENT, MATERIALS OR PRODUCTS INCLUDED ON THIS SITE. ALL WARRANTIES ARE DISCLAIMED,
                  EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED 
                  WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR 
                  PURPOSE. WITHOUT LIMITING ABOVE, ALL WARRANTIES ARE DISCLAIMED,
                  EXPRESS OR IMPLIED, OF ANY KIND WHATSOEVER. YOU ACKNOWLEDGE, 
                  BY USING THE SITE, THAT YOUR USE OF THE SITE IT IS AT YOUR OWN
                  RISK. NOT A FINANCIAL INSTRUMENT OR INVESTMENT. NOT FINANCIAL
                  ADVICE. DO YOUR OWN RESEARCH. THE SITE IS AN ART/ENTERTAINMENT/FANTASY PROJECT AND IS FOR 
                  ENTERTAINMENT PURPOSES ONLY.
                </small>

                <small>
                  THERE ARE NO PROMISES OR GUARANTEES OF ANY PROFITS OR PERFORMANCE. CONTRACTS ARE WRITTEN 
                  AS-IS AND SHOULD BE REVIEWD BEFORE INTERACTING. THERE IS NO ROADMAP, NO CONTROLS, NO ONGOING 
                  ACTIVITIES. THE ENTIRE PROJECT IS AUTOMATIC AND CAN BE INSPECTED ON-CHAIN. IF YOU ARE IN 
                  A JURISTICTION THAT PREVENTS SUCH ACITVITIES OR ARE SANCTIONED OR IN A SANCTIOND JURISTICTION 
                  YOUR PARTICIPATION IN THIS PROJECT IS VOID AND PROHIBITED. THIS PROJECT IS DESINGED AND MEANT 
                  TO BE ATONOMOUS.
                </small>
                <br /><br />
<pre>
    <b>polygon:</b><br />
        tsoul: 0x2Eb641F82d2Bbf4eEF47a14e9EcCb5548FFa1354<br />
       stsoul: 0xa0786154afe25ffDcbD41fFA482f2D87Ebd961F9<br />
      soulnft: 0xe03fCD36BDE68c6AE0A855479C9C100A4Fc004d3<br />
      presale: 0x1DFea1C1D55E43B5194Fb382AB7a3585B8862941<br />
    <b>binance:</b><br />
        tsoul: 0x6931c60E5ED04B563357Af8551f3E39EC479838B<br />
       stsoul: 0x963810761BDd949A46b56Be4DDD6C7453E92eeD1<br />
      soulnft: 0xe6c10E092978D9d08e376ac4480Ab94B7EF1C43b<br />
      presale: 0x2dC91F4c4BafEd5F6A1f1cA06cE283ECF27A0604<br />
    <b>pulse:</b><br />
        tsoul: 0x1bA8f19ea30F4DD7e89835f7873EE5FE9B6E1DEc<br />
       stsoul: 0x5ddC55219050d10F4E048bCD6FaeE822fAD18fF7<br />
      soulnft: 0xA807f6828A6C08A894D582263e548d9D5a7a014d<br />
      presale: 0x400872212bF993512b36FeeF4C6bD431B216195b<br />
</pre>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2023. SoulFi&copy; The Soul Collection&copy; All Rights Reserved.  </p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
