import React from "react";
import img01 from "../../assets/img/images/revenue.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../lib/helpers";

const Revenue = (props) => {
  return (
    <section id="revenue" className="about-area pt-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                  <span>OUTSIDE REVENUE</span> no dilution!
                </h2>
              </div>
              <p>
                All <b>REWARDS COME FROM OUTSIDE REVENUE</b> There 
                is <b>ZERO TOKEN DILUTION</b>. 
              </p>
              <p>
                SoulNFT Mints, Bridge traffic, Arbitrage traffic, Trading traffic, ...
              </p>
              <button className="btn" onClick={scrollToTop}>
                Purchase Presale
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Revenue;
