import React from "react";
import img01 from "../../assets/img/images/staking.png";
import img02 from "../../assets/img/images/about_img02.png";
import { scrollToTop } from "../../lib/helpers";

const Staking = (props) => {
  return (
    <section id="staking" className="about-area pt-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                  <span>Staking Rewards</span>
                </h2>
              </div>
              <p>
                Unique <b>NON-PONZI REVENUE GENERATION</b>! Get rewarded with <b>ZERO 
                DILUTION</b> and sTSOUL <b>LIQUID STAKING</b>. Automatic rewards for just holding! 
              </p>
              <p>
                All tokens for <b>REWARDS ARE BOUGHT OFF THE MARKET</b>, from existing holders and all rewards
                are <b>PAID OUT IN REAL TIME</b> for just holding sTSOUL in your wallet.
              </p>
              <div>
                <ul>
                  <li><b>2.5% on all TSOUL sells</b></li>
                  <li><b>$5 for every SoulNFT mint</b></li>
                </ul>
              </div>
              <p>
                All Rewards are <b>PURCHASED OFF THE MARKET</b> creating buying pressure for TSOUL! There
                are <b>NO TOKEN EMISSIONS OR DILUTION</b> to bring down your staked value! Only degen APRs from 
                outside action, rewarded directly to Stakers!
              </p>
              <button className="btn" onClick={scrollToTop}>
                Purchase Presale
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Staking;
