import React from "react";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import CountDown from "../CountDown/CountDown";
import { handleClickScroll } from "../../lib/helpers";
import { Link, useLocation } from "react-router-dom";
import Buybox from "../Buybox/Buybox";

const Banner = (props) => {
	return (
		<section className="banner-area banner-bg">
			<div className="banner-shape-wrap">
				<img src={shape01} alt="" className="img-one" />
				<img src={shape03} alt="" className="img-three" />
			</div>

			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-10">
						<div className="row justify-content-center text-center">
							<h2 className="title">
								<Link to="https://app.soulfi.net">
								<br />
								<br />
								<br />
								<br />
									DAPP LIVE! CLICK HERE!
									<br />
									<br />
									<br />
									<br />
								</Link>
							</h2>
						</div>

						<div className="justify-content-center text-center">
							<Link to="#tokenomics" className="white" onClick={() => handleClickScroll("tokenomics")}>
								Fair Launch
							</Link>{" "}
							&#9676;&nbsp;
							<Link to="#staking" className="white" onClick={() => handleClickScroll("staking")}>
								Insane APRs
							</Link>{" "}
							&#9676;&nbsp;
							<Link to="#inflation" className="white" onClick={() => handleClickScroll("inflation")}>
								Zero Inflation
							</Link>{" "}
							&#9676;&nbsp;
							<Link to="#liquidity" className="white" onClick={() => handleClickScroll("liquidity")}>
								DEEP Liquidity
							</Link>{" "}
							&#9676;&nbsp;
							<Link to="#nft" className="white" onClick={() => handleClickScroll("nft")}>
								SoulNFTs
							</Link>{" "}
							&#9676;&nbsp;
							<Link to="#revenue" className="white" onClick={() => handleClickScroll("revenue")}>
								Outside Revenue
							</Link>{" "}
							&#9676;&nbsp;
							<Link to="#arbitrage" className="white" onClick={() => handleClickScroll("arbitrage")}>
								Arbitrage
							</Link>{" "}
							&#9676;&nbsp;
							<Link to="#bridge" className="white" onClick={() => handleClickScroll("bridge")}>
								Cross-Chain Native
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Banner;
