import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./PageLoader.module.css";
import { Link, useLocation } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo.png";
import logoBSC from "../../../src/assets/img/icon/bsc.png";
import logoPulse from "../../../src/assets/img/icon/pulse.png";
import logoEthereum from "../../../src/assets/img/icon/ethereum.png";
import logoPolygon from "../../../src/assets/img/icon/polygon.png";

const PageLoader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  if (loading) {
    return (
      <div className={cn(styles.preloader)}>
        <div className={cn(styles.spinner)}>
          <div className={cn(styles.rect1)}></div>
          <div className={cn(styles.rect2)}></div>
          <div className={cn(styles.rect3)}></div>
          <div className={cn(styles.rect4)}></div>
          <div className={cn(styles.rect5)}></div>
        </div>
        <br />
        <div className={cn(styles.logos)}>
          <Link to={"/"}>
            <img src={logoPolygon} alt="Polygon Chain Logo" />
          </Link>
          &nbsp;
          <Link to={"/"}>
            <img src={logoPulse} alt="Pulse Chain Logo" />
          </Link>
          &nbsp;
          <Link to={"/"}>
            <img src={logoEthereum} alt="Ethereum Chain Logo" />
          </Link>
          &nbsp;
          <Link to={"/"}>
            <img src={logoBSC} alt="Binance Smart Chain Logo" />
          </Link>
          &nbsp;
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PageLoader;
