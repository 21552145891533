import React from "react";
import { scrollToTop } from "../../lib/helpers";

const TLDR = (props) => {
  return (
    <section id="tldr" className="about-area pt-100">
      <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">

                <h2 className="title">
                  <span>THE TLDR;</span>
                </h2>
              <p>
                <ul className="tldr-list">
                  <li>100m TSOUL Tokens max combined. All unused tokens after presale are burned.</li>
                  <li>Available presale tokens are split evenly between the 4 chains</li>
                  <li>Zero inflation, no emissions</li>
                  <li>Liquidity tokens added at launch and burned</li>
                  <li>Liquidity added to Ethereum/Binance/Polygon/Pulsechain</li>
                  <li>Token is cross chain native between all 4 chains</li>
                  <li>5% Sell tax</li>
                  <li>No transfer tax or buy tax</li>
                </ul>
              </p>
              <p>
                <ul className="tldr-list">
                  <li>TSOUL available for $0.0600 on October 28th</li>
                  <li>Price rises to $0.0660 on the 29th</li>
                  <li>Price rises to $0.0666 on the 30th</li>
                  <li>Price rises to $0.0777 on the 31st and liquidity launches/trading begins</li>
                </ul>
              </p>
              <p>
                <ul className="tldr-list">
                  <li>Stake TSOUL LP tokens for sTSOUL</li>
                  <li>sTSOUL holders rewarded 50% of TSOUL sell tax</li>
                  <li>sTSOUL holders rewarded $5 for every SoulNFT sale</li>
                  <li>Staking opens on October 31st</li>
                </ul>
              </p>
              <p>
                <ul className="tldr-list">
                  <li>SoulNFT minting goes live October 31st</li>
                  <li>Each SoulNFT costs $30 to mint</li>
                  <li>The soul NFT is signed with a soul contract</li>
                  <li>Souls may be transfered, collected, sold</li>
                </ul>
              </p>
              <button className="btn" onClick={scrollToTop}>
                Purchase Presale
              </button>
          </div>
          <div className="col-lg-6">
          </div>
        </div>
      </div>
    </section>
  );
};

export default TLDR;
