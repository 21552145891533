import React from "react";

const DEX = (props) => {
	return (
		<section id="dex" className="dex pt-130">
			<div className="row justify-content-center">
				<iframe
					src="https://anytoany.io/?source=0x57c9b29c988479940Df3d71FAF9C65DeeA2b25A6&bg=15151b&backdrop=000000&backdrop-opacity=80"
					allow="ethereum; clipboard-write self https://anytoany.io/"
					width="345px"
					title="Swap your tokens with ease"
					height="640px"
					frameBorder="0"
				></iframe>
			</div>
		</section>
	);
};
export default DEX;
