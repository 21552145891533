import React from "react";
import img01 from "../../assets/img/images/inflation.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../lib/helpers";

const Inflation = (props) => {
  return (
    <section id="inflation" className="about-area pt-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                  <span>ZERO INFLATION</span> no token emissions after launch.
                </h2>
              </div>
              <p>
                <b>NO INFLATION, NO MINTING TOKENS</b>, all rewards are purchased 
                off the market from existing holders from outside revenue. <b>ZERO DILUTION</b>.
              </p>
              <p>
                We do not inflate our token supply to provide boosted returns which eventually 
                leads to the slow death of the platform due to inflation. We are <b>ZERO INFLATION</b>.
              </p>
              <button className="btn" onClick={scrollToTop}>
                Purchase Presale
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inflation;
