import React from "react";
import Countdown from "react-countdown";

const BuyboxPurchaseLevel = ({endTime, salePrice}) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          ENDED
        </>
      );
    } else {
      return (
        <>
            { days    }d&nbsp;
            { hours   }h&nbsp;
            { minutes }m&nbsp; 
            { seconds }s&nbsp; 
        </>
      );
    }
  };

  return (
    <div className="text-start sc-level" style={{width: 275}}>
      {salePrice}&nbsp;
      <Countdown date={endTime} renderer={renderer} />
    </div>
  );
};

export default BuyboxPurchaseLevel;
