import React from "react";
import img01 from "../../assets/img/images/tokenomics.png";
import img02 from "../../assets/img/images/about_img02.png";
import { scrollToTop } from "../../lib/helpers";

const Tokenomics = (props) => {
	return (
		<section id="tokenomics" className="about-area pt-30">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="about-content wow fadeInRight" data-wow-delay=".2s">
							<div className="section-title mb-30">
								<h2 className="title">
									<span>Fair Token</span> Distribution with <span>Deep Liquidity</span>
								</h2>
							</div>
							<p>
								Presale is available at the same schedule on all of our chains. <b>NO OUTSIDE DILUTION OR OTC SALES</b>.
							</p>
							<ul>
								<li>60% PRESALE</li>
								<li>40% LIQUIDITY</li>
								<li>NO TEAM ALLOCATION</li>
							</ul>
							<p>
								Liquidity is added from the token presale automatically and all <b>UNSOLD TOKENS ARE BURNED AUTOMATICALLY</b> on
								launch.
							</p>
							<ul>
								<li>No Buy Tax</li>
								<li>5% Anti-Dump Sell Tax</li>
								<li>No Emissions, No Dilution</li>
								<li>Liquid Staking Options for degen APRs</li>
							</ul>
							<button className="btn" onClick={scrollToTop}>
								Purchase Presale
							</button>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-img wow fadeInLeft" data-wow-delay=".2s">
							<img src={img01} alt="" />
							<img src={img02} alt="" className="img-two" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Tokenomics;
