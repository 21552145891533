import React from "react";
import img01 from "../../assets/img/images/arbitrage.png";
import img02 from "../../assets/img/images/about_img02.png";
import { scrollToTop } from "../../lib/helpers";

const Arbitrage = (props) => {
  return (
    <section id="arbitrage" className="about-area pt-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <h2 className="title">
                  <span>Profit</span> from <span>Arbitrage!</span>
                </h2>
              </div>
              <p>
                Tokens are <b>CROSS CHAIN NATIVE</b>. By buying tokens cheaper on one
                chain and selling them for more on another chain, you get
                to <b>KEEP THE PROFIT</b>!
              </p>
              <p>
                Token transfers are <b>LIGHTNING FAST</b>, and allow you to quickly profit 
                from market volitlity and price mismatches between tSOUL and well as 
                the native blockchain tokens: <b>BNB, MATIC, ETH, </b>and <b>PULSE</b>.
              </p>
              <button className="btn" onClick={scrollToTop}>
                Purchase Presale
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Arbitrage;
